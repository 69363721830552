import React from 'react';
import { Layout, PerfilComponent, PrivateRoute } from 'components';

export default function PerfilPage() {
  const mainComponent = (
    <Layout home={true}>
      <PerfilComponent />
    </Layout>
  );
  return <PrivateRoute component={mainComponent} />;
}
